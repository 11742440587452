.FormSteps.ui.steps > .ui.step {
  padding-left: 0;
  padding-right: 0;
}

.FormSteps.ui.steps > .ui.step .title {
  font-size: 1em;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {
  .FormSteps > .step > .content {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
  }

  .FormSteps.ui.steps > .ui.step {
    padding-left: 1rem;
  }

  .FormSteps.ui.steps .disabled.step {
    display: none;
  }

  /* If all steps are disabled, we still have to show the first one on mobile to prevent seeing no steps at all */
  .FormSteps.ui.steps.allDisabled .disabled.step:first-child {
    display: inline;
  }

  /*
  .FormSteps.stackable.ui.steps > .step {
    display: none;
  }

  .FormSteps.stackable.ui.steps > .step.previous {
    display: inline;
  }
  .FormSteps.stackable.ui.steps > .step.active {
    display: inline;
  }
  .FormSteps.stackable.ui.steps > .step.active + .step {
    display: inline;
  }*/
}
