.FormSteps.ui.steps > .ui.step {
  padding-left: 0;
  padding-right: 0;
}

.FormSteps.ui.steps > .ui.step .title {
  font-size: 1em;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {
  .FormSteps > .step > .content {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
  }

  .FormSteps.ui.steps > .ui.step {
    padding-left: 1rem;
  }

  .FormSteps.ui.steps .disabled.step {
    display: none;
  }

  /* If all steps are disabled, we still have to show the first one on mobile to prevent seeing no steps at all */
  .FormSteps.ui.steps.allDisabled .disabled.step:first-child {
    display: inline;
  }

  /*
  .FormSteps.stackable.ui.steps > .step {
    display: none;
  }

  .FormSteps.stackable.ui.steps > .step.previous {
    display: inline;
  }
  .FormSteps.stackable.ui.steps > .step.active {
    display: inline;
  }
  .FormSteps.stackable.ui.steps > .step.active + .step {
    display: inline;
  }*/
}

.Preview {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-top: 14px;
  padding: 21px 14px 14px 14px;
  height: calc(100% - 2 * 14px - 14px);
  background-color: #e2e1e01a;
  border-radius: 2px;
  box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Palatino", "Palatino LT W01 Roman", serif;
  position: relative;
  white-space: pre-line;
}

.Preview > img {
  position: absolute;
  top: -35px;
}

.Preview > .title {
  margin-bottom: 14px;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  font-size: 1.4rem;
  text-align: center;
}

.Preview ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  white-space: normal;
}

.Preview li {
  padding-left: 1.4em;
  text-indent: -1.4em;
  padding-bottom: 0.2em;
}

.Preview li:before {
  content: "\2014";
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding-right: 5px;
}

.ui.buttons .or::before {
  content: " ";
}

.ui.form fieldset {
  border: 0;
  padding-top: 1em;
  padding-bottom: 1em;
}

.ui.form .field fieldset .ui.input input {
  width: 100%;
}

/* No extra spacing for the first level of fieldsets */
.column > .ui.form > .field > fieldset,
.column > .ui.form > .field > fieldset > .field > fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.ui.stackable.grid.TemplatePageForm > .row > .column {
  padding: 1rem 1rem !important;
}

.ui.form fieldset legend#root__title {
  font-size: 1.3em;
  margin-bottom: 0.7em;
}

.ui.form .help-block {
  color: #555;
}

.ui.form fieldset legend,
.ui.form .field > label {
  text-transform: none;
  font-size: 1em;
  font-weight: 400;
}

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="url"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"] {
  padding-left: 1em;
}

.joyride-beacon {
  z-index: 800 !important;
}

.ContractWizard .segment.bottom {
  display: flex;
  justify-content: space-between;
}

