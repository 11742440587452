.Preview {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 14px;
  padding: 21px 14px 14px 14px;
  height: calc(100% - 2 * 14px - 14px);
  background-color: #e2e1e01a;
  border-radius: 2px;
  box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: "Palatino", "Palatino LT W01 Roman", serif;
  position: relative;
  white-space: pre-line;
}

.Preview > img {
  position: absolute;
  top: -35px;
}

.Preview > .title {
  margin-bottom: 14px;
  font-variant: small-caps;
  font-size: 1.4rem;
  text-align: center;
}

.Preview ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  white-space: normal;
}

.Preview li {
  padding-left: 1.4em;
  text-indent: -1.4em;
  padding-bottom: 0.2em;
}

.Preview li:before {
  content: "—";
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding-right: 5px;
}
