.ui.buttons .or::before {
  content: " ";
}

.ui.form fieldset {
  border: 0;
  padding-top: 1em;
  padding-bottom: 1em;
}

.ui.form .field fieldset .ui.input input {
  width: 100%;
}

/* No extra spacing for the first level of fieldsets */
.column > .ui.form > .field > fieldset,
.column > .ui.form > .field > fieldset > .field > fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.ui.stackable.grid.TemplatePageForm > .row > .column {
  padding: 1rem 1rem !important;
}

.ui.form fieldset legend#root__title {
  font-size: 1.3em;
  margin-bottom: 0.7em;
}

.ui.form .help-block {
  color: #555;
}

.ui.form fieldset legend,
.ui.form .field > label {
  text-transform: none;
  font-size: 1em;
  font-weight: 400;
}

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="url"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"] {
  padding-left: 1em;
}
